import React from 'react';
import Sidebar from '../DashBoardSideBar/Sidebar';
import SliderForm from './Sliderform';
import styles from './Dashboard.module.css';
import bannerImg from "../../Assets/3iBannerDashBoard.svg";


const Dashboard = () => {
  return (
    <div className={styles.dashboardContainer}>
      <Sidebar />
      <div className = {styles.content}>
        <div className={styles.bannerContainer}>
          <img src={bannerImg} alt="Banner" className={styles.bannerImage} />
        </div>
        <div className={styles.mainContent}>
          <SliderForm />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
