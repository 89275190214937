import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ref as dbRef, get, set } from 'firebase/database';
import { database, storage } from '../../firebaseConfig';
import { useAuth } from '../../Context/AuthContext';
import styles from './EditSliderForm.module.css';

const EditSlider = () => {
  const location = useLocation();
  const { slider } = location.state || {};
//   console.log(slider);
  const [sliderTitle, setSliderTitle] = useState(slider?.slider_title || '');
  const [sliderImage, setSliderImage] = useState(null);
  const [sliderImageURL, setSliderImageURL] = useState(slider?.slider_image || '');
  const [status, setStatus] = useState(slider?.slider_status ? 'enable' : 'disable');
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleTitleChange = (e) => {
    setSliderTitle(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSliderImage(file);
    // Create a local URL to display the selected image immediately
    setSliderImageURL(URL.createObjectURL(file));
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (loading) {
      console.error('Authentication check is still loading');
      return;
    }

    if (!user) {
      console.error('User is not authenticated');
      navigate('/slider');
      return;
    }

    try {
      let downloadURL = slider?.slider_image;

      if (sliderImage) {
        // Generate a unique file name
        const timestamp = Date.now();
        const uniqueFileName = `${timestamp}_${sliderImage.name}`;
        const imageRef = storageRef(storage, `sliders/${uniqueFileName}`);
        console.log('Uploading image to:', imageRef.fullPath);

        // Upload the file
        await uploadBytes(imageRef, sliderImage);
        console.log('Image uploaded successfully');

        // Get the download URL
        downloadURL = await getDownloadURL(imageRef);
        console.log('Image URL:', downloadURL);
      }

      const updatedSlider = {
        slider_image: downloadURL,
        slider_title: sliderTitle,
        slider_status: status === 'enable',
      };

      // Save the updated slider data to Realtime Database
      const sliderRef = dbRef(database, `3I_Consulting/sliders/${slider.id}`);
      await set(sliderRef, updatedSlider);

      console.log('Slider data saved:', updatedSlider);
      navigate('/slider');
    } catch (error) {
      console.error('Error uploading image or saving data:', error.message);
      console.error('Stack trace:', error.stack);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className={styles.form}>
      <h2>Edit Slider</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="sliderTitle">Slider Title</label>
          <input
            type="text"
            id="sliderTitle"
            value={sliderTitle}
            onChange={handleTitleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="sliderImage">Slider Image</label>
          <input
            type="file"
            id="sliderImage"
            onChange={handleImageChange}
            accept="image/*"
          />
          {sliderImageURL && (
            <div className={styles.imagePreview}>
              <img src={sliderImageURL} alt="Slider Preview" className={styles.previewImage} />
            </div>
          )}
        </div>
        <div className={styles.formGroup}>
          <label>Status</label>
          <div className={styles.radioGroup}>
            <label>
              <input
                type="radio"
                value="enable"
                checked={status === 'enable'}
                onChange={handleStatusChange}
              />
              Enable
            </label>
            <label>
              <input
                type="radio"
                value="disable"
                checked={status === 'disable'}
                onChange={handleStatusChange}
              />
              Disable
            </label>
          </div>
        </div>
        <button type="submit" className={styles.submitButton}>Submit</button>
      </form>
    </div>
  );
};

export default EditSlider;
