import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ref as dbRef, get, set } from 'firebase/database';
import { database, storage } from '../../firebaseConfig';
import { useAuth } from '../../Context/AuthContext';
import styles from './SliderForm.module.css';

const SliderForm = () => {
  const [sliderTitle, setSliderTitle] = useState('');
  const [sliderImage, setSliderImage] = useState(null);
  const [status, setStatus] = useState('disable');
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleTitleChange = (e) => {
    setSliderTitle(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSliderImage(file);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (loading) {
      console.error('Authentication check is still loading');
      return;
    }
  
    if (!user) {
      console.error('User is not authenticated');
      navigate('/slider');
      return;
    }
  
    if (!sliderImage) {
      console.error('No image selected');
      return;
    }
  
    try {
      // Generate a unique file name
      const timestamp = Date.now();
      const uniqueFileName = `${timestamp}_${sliderImage.name}`;
      const imageRef = storageRef(storage, `sliders/${uniqueFileName}`);
      console.log('Uploading image to:', imageRef.fullPath);
  
      // Upload the file
      await uploadBytes(imageRef, sliderImage);
      console.log('Image uploaded successfully');
  
      // Get the download URL
      const downloadURL = await getDownloadURL(imageRef);
      console.log('Image URL:', downloadURL);
  
      const slidersRef = dbRef(database, `3I_Consulting/sliders/`);
      const snapshot = await get(slidersRef);
      const existingData = snapshot.val();
      console.log('Existing data:', existingData);
  
      // Determine the new key for the slider
      let maxKey = 0;
      if (existingData) {
        maxKey = Math.max(...Object.keys(existingData).map(Number), 0);
      }
  
      const newKey = maxKey + 1;
      console.log('New key:', newKey);
  
      // Define the slider object
      const newSlider = {
        slider_image: downloadURL,
        slider_title: sliderTitle,
        slider_status: status === 'enable'
      };
  
      // Save the slider data to Realtime Database
      const newSliderRef = dbRef(database, `3I_Consulting/sliders/${newKey}`);
      await set(newSliderRef, newSlider);
  
      console.log('Slider data saved:', newSlider);
      navigate('/slider');
    } catch (error) {
      console.error('Error uploading image or saving data:', error.message);
      console.error('Stack trace:', error.stack);
    }
  };
  

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className={styles.form}>
      <h2>Add Slider</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="sliderTitle">Slider Title</label>
          <input
            type="text"
            id="sliderTitle"
            value={sliderTitle}
            onChange={handleTitleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="sliderImage">Slider Image</label>
          <input
            type="file"
            id="sliderImage"
            onChange={handleImageChange}
            accept="image/*"
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label>Status</label>
          <div className={styles.radioGroup}>
            <label>
              <input
                type="radio"
                value="enable"
                checked={status === 'enable'}
                onChange={handleStatusChange}
              />
              Enable
            </label>
            <label>
              <input
                type="radio"
                value="disable"
                checked={status === 'disable'}
                onChange={handleStatusChange}
              />
              Disable
            </label>
          </div>
        </div>
        <button type="submit" className={styles.submitButton}>Submit</button>
      </form>
    </div>
  );
};

export default SliderForm;
