


export const AllProduct = [
    {
        id : 'temple_Mangmnt',
        // heroImg : 'https://unsplash.com/photos/two-people-shaking-hands-n95VMLxqM2I',
        hero : 'https://images.pexels.com/photos/6488342/pexels-photo-6488342.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        h1 : 'Temple Management System',

        bannerImg : 'https://images.pexels.com/photos/4533747/pexels-photo-4533747.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        features : {
            first : 'admin Dashboard',
            second : 'donation',
            third : 'event puja scheduling',
            fourth :  'devotee mangmnt'
        },

        description : 'Our Temple Management System is a comprehensive solution designed to streamline and enhance the administration and operations of temples. This system integrates various functions to facilitate efficient management of temple activities, donations, events, and communications. By leveraging advanced technology, we aim to support temples in maintaining their spiritual and administrative responsibilities with ease and effectiveness.',

        firstProjectTitle : 'The Protocal System',

        firstProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        firstProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        firstProjectBtnLink : '/',

        secondProjectTitle : 'The POS System', 
        
        secondProjectImg: 'https://images.pexels.com/photos/5756687/pexels-photo-5756687.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        secondProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        secondProjectBtnLink : '/',

        thirdProjectTitle : 'The Inventory System', 
        
        thirdProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        thirdProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        thirdProjectBtnLink : '/',

        

    },
    {
        id : 'Hrms',

        hero : 'https://images.pexels.com/photos/6113138/pexels-photo-6113138.jpeg?auto=compress&cs=tinysrgb&w=600',

        h1 : 'Human Resource Management System',

        bannerImg : 'https://images.pexels.com/photos/4533747/pexels-photo-4533747.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        features : {
            first : 'admin Dashboard',
            second : 'donation',
            third : 'event scheduling',
            fourth :  'hr mangmnt'
        },

        description : 'Our Hrms Management System is a comprehensive solution designed to streamline and enhance the administration and operations of temples. This system integrates various functions to facilitate efficient management of temple activities, donations, events, and communications. By leveraging advanced technology, we aim to support temples in maintaining their spiritual and administrative responsibilities with ease and effectiveness.',

        firstProjectTitle : 'The Protocal System',


        firstProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        firstProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        firstProjectBtnLink : '/',


        secondProjectTitle : 'The Protocal System',

        secondProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        secondProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        secondProjectBtnLink : '/',

        thirdProjectTitle : 'The Protocal System',

        
        thirdProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        thirdProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        thirdProjectBtnLink : '/',


    },
    {
        id : 'project_mngmnt',

        hero : 'https://images.pexels.com/photos/6113138/pexels-photo-6113138.jpeg?auto=compress&cs=tinysrgb&w=600',

        h1 : 'Project Management',

        bannerImg : 'https://images.pexels.com/photos/4533747/pexels-photo-4533747.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        features : {
            first : 'admin Dashboard',
            second : 'donation',
            third : 'event scheduling',
            fourth :  'hr mangmnt'
        },

        description : 'Our Hrms Management System is a comprehensive solution designed to streamline and enhance the administration and operations of temples. This system integrates various functions to facilitate efficient management of temple activities, donations, events, and communications. By leveraging advanced technology, we aim to support temples in maintaining their spiritual and administrative responsibilities with ease and effectiveness.',

        firstProjectTitle : 'The Protocal System',


        firstProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        firstProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        firstProjectBtnLink : '/',


        secondProjectTitle : 'The Protocal System',

        secondProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        secondProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        secondProjectBtnLink : '/',

        thirdProjectTitle : 'The Protocal System',

        
        thirdProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        thirdProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        thirdProjectBtnLink : '/',


    },
    {
        id : 'OPD_System',

        hero : 'https://images.pexels.com/photos/6113138/pexels-photo-6113138.jpeg?auto=compress&cs=tinysrgb&w=600',

        h1 : 'OPD System',

        bannerImg : 'https://images.pexels.com/photos/4533747/pexels-photo-4533747.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        features : {
            first : 'admin Dashboard',
            second : 'donation',
            third : 'event scheduling',
            fourth :  'hr mangmnt'
        },

        description : 'Our Hrms Management System is a comprehensive solution designed to streamline and enhance the administration and operations of temples. This system integrates various functions to facilitate efficient management of temple activities, donations, events, and communications. By leveraging advanced technology, we aim to support temples in maintaining their spiritual and administrative responsibilities with ease and effectiveness.',

        firstProjectTitle : 'The Protocal System',


        firstProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        firstProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        firstProjectBtnLink : '/',


        secondProjectTitle : 'The Protocal System',

        secondProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        secondProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        secondProjectBtnLink : '/',

        thirdProjectTitle : 'The Protocal System',

        
        thirdProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        thirdProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        thirdProjectBtnLink : '/',


    },
    {
        id : 'lab',

        hero : 'https://images.pexels.com/photos/6113138/pexels-photo-6113138.jpeg?auto=compress&cs=tinysrgb&w=600',

        h1 : 'Lab Ssytem',

        bannerImg : 'https://images.pexels.com/photos/4533747/pexels-photo-4533747.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        features : {
            first : 'admin Dashboard',
            second : 'donation',
            third : 'event scheduling',
            fourth :  'hr mangmnt'
        },

        description : 'Our Hrms Management System is a comprehensive solution designed to streamline and enhance the administration and operations of temples. This system integrates various functions to facilitate efficient management of temple activities, donations, events, and communications. By leveraging advanced technology, we aim to support temples in maintaining their spiritual and administrative responsibilities with ease and effectiveness.',

        firstProjectTitle : 'The Protocal System',


        firstProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        firstProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        firstProjectBtnLink : '/',


        secondProjectTitle : 'The Protocal System',

        secondProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        secondProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        secondProjectBtnLink : '/',

        thirdProjectTitle : 'The Protocal System',

        
        thirdProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        thirdProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        thirdProjectBtnLink : '/',


    },
    {
        id : 'learning_mngmnt',

        hero : 'https://images.pexels.com/photos/6113138/pexels-photo-6113138.jpeg?auto=compress&cs=tinysrgb&w=600',

        h1 : 'learning Sytem',

        bannerImg : 'https://images.pexels.com/photos/4533747/pexels-photo-4533747.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        features : {
            first : 'admin Dashboard',
            second : 'donation',
            third : 'event scheduling',
            fourth :  'hr mangmnt'
        },

        description : 'Our Hrms Management System is a comprehensive solution designed to streamline and enhance the administration and operations of temples. This system integrates various functions to facilitate efficient management of temple activities, donations, events, and communications. By leveraging advanced technology, we aim to support temples in maintaining their spiritual and administrative responsibilities with ease and effectiveness.',

        firstProjectTitle : 'The Protocal System',


        firstProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        firstProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        firstProjectBtnLink : '/',


        secondProjectTitle : 'The Protocal System',

        secondProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        secondProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        secondProjectBtnLink : '/',

        thirdProjectTitle : 'The Protocal System',

        
        thirdProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        thirdProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        thirdProjectBtnLink : '/',


    },
    {
        id : 'inventory_mngmnt',

        hero : 'https://images.pexels.com/photos/6113138/pexels-photo-6113138.jpeg?auto=compress&cs=tinysrgb&w=600',

        h1 : 'Inventory management system',

        bannerImg : 'https://images.pexels.com/photos/4533747/pexels-photo-4533747.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        features : {
            first : 'admin Dashboard',
            second : 'donation',
            third : 'event scheduling',
            fourth :  'hr mangmnt'
        },

        description : 'Our Hrms Management System is a comprehensive solution designed to streamline and enhance the administration and operations of temples. This system integrates various functions to facilitate efficient management of temple activities, donations, events, and communications. By leveraging advanced technology, we aim to support temples in maintaining their spiritual and administrative responsibilities with ease and effectiveness.',

        firstProjectTitle : 'The Protocal System',


        firstProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        firstProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        firstProjectBtnLink : '/',


        secondProjectTitle : 'The Protocal System',

        secondProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        secondProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        secondProjectBtnLink : '/',

        thirdProjectTitle : 'The Protocal System',

        
        thirdProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        thirdProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        thirdProjectBtnLink : '/',


    },
    {
        id : 'legal_mngmnt',

        hero : 'https://images.pexels.com/photos/6113138/pexels-photo-6113138.jpeg?auto=compress&cs=tinysrgb&w=600',

        h1 : 'Legal management system',

        bannerImg : 'https://images.pexels.com/photos/4533747/pexels-photo-4533747.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        features : {
            first : 'admin Dashboard',
            second : 'donation',
            third : 'event scheduling',
            fourth :  'hr mangmnt'
        },

        description : 'Our Hrms Management System is a comprehensive solution designed to streamline and enhance the administration and operations of temples. This system integrates various functions to facilitate efficient management of temple activities, donations, events, and communications. By leveraging advanced technology, we aim to support temples in maintaining their spiritual and administrative responsibilities with ease and effectiveness.',

        firstProjectTitle : 'The Protocal System',


        firstProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        firstProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        firstProjectBtnLink : '/',


        secondProjectTitle : 'The Protocal System',

        secondProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        secondProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        secondProjectBtnLink : '/',

        thirdProjectTitle : 'The Protocal System',

        
        thirdProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        thirdProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        thirdProjectBtnLink : '/',


    },
    {
        id : 'property_mngmnt',

        hero : 'https://images.pexels.com/photos/6113138/pexels-photo-6113138.jpeg?auto=compress&cs=tinysrgb&w=600',

        h1 : 'Property management system',

        bannerImg : 'https://images.pexels.com/photos/4533747/pexels-photo-4533747.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        features : {
            first : 'admin Dashboard',
            second : 'donation',
            third : 'event scheduling',
            fourth :  'hr mangmnt'
        },

        description : 'Our Hrms Management System is a comprehensive solution designed to streamline and enhance the administration and operations of temples. This system integrates various functions to facilitate efficient management of temple activities, donations, events, and communications. By leveraging advanced technology, we aim to support temples in maintaining their spiritual and administrative responsibilities with ease and effectiveness.',

        firstProjectTitle : 'The Protocal System',


        firstProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        firstProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        firstProjectBtnLink : '/',


        secondProjectTitle : 'The Protocal System',

        secondProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        secondProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        secondProjectBtnLink : '/',

        thirdProjectTitle : 'The Protocal System',

        
        thirdProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        thirdProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        thirdProjectBtnLink : '/',


    },
    {
        id : 'tourism_mngmnt',

        hero : 'https://images.pexels.com/photos/6113138/pexels-photo-6113138.jpeg?auto=compress&cs=tinysrgb&w=600',

        h1 : 'Tourism management system',

        bannerImg : 'https://images.pexels.com/photos/4533747/pexels-photo-4533747.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        features : {
            first : 'admin Dashboard',
            second : 'donation',
            third : 'event scheduling',
            fourth :  'hr mangmnt'
        },

        description : 'Our Hrms Management System is a comprehensive solution designed to streamline and enhance the administration and operations of temples. This system integrates various functions to facilitate efficient management of temple activities, donations, events, and communications. By leveraging advanced technology, we aim to support temples in maintaining their spiritual and administrative responsibilities with ease and effectiveness.',

        firstProjectTitle : 'The Protocal System',


        firstProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        firstProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        firstProjectBtnLink : '/',


        secondProjectTitle : 'The Protocal System',

        secondProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        secondProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        secondProjectBtnLink : '/',

        thirdProjectTitle : 'The Protocal System',

        
        thirdProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        thirdProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        thirdProjectBtnLink : '/',


    },
    {
        id : 'e_dak_mngmnt',

        hero : 'https://images.pexels.com/photos/6113138/pexels-photo-6113138.jpeg?auto=compress&cs=tinysrgb&w=600',

        h1 : 'E-Dak management system',

        bannerImg : 'https://images.pexels.com/photos/4533747/pexels-photo-4533747.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        features : {
            first : 'admin Dashboard',
            second : 'donation',
            third : 'event scheduling',
            fourth :  'hr mangmnt'
        },

        description : 'Our Hrms Management System is a comprehensive solution designed to streamline and enhance the administration and operations of temples. This system integrates various functions to facilitate efficient management of temple activities, donations, events, and communications. By leveraging advanced technology, we aim to support temples in maintaining their spiritual and administrative responsibilities with ease and effectiveness.',

        firstProjectTitle : 'The Protocal System',


        firstProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        firstProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        firstProjectBtnLink : '/',


        secondProjectTitle : 'The Protocal System',

        secondProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        secondProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        secondProjectBtnLink : '/',

        thirdProjectTitle : 'The Protocal System',

        
        thirdProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        thirdProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        thirdProjectBtnLink : '/',


    },
    {
        id : 'visitor_mngmnt',

        hero : 'https://images.pexels.com/photos/6113138/pexels-photo-6113138.jpeg?auto=compress&cs=tinysrgb&w=600',

        h1 : 'Visitor management system',

        bannerImg : 'https://images.pexels.com/photos/4533747/pexels-photo-4533747.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        features : {
            first : 'admin Dashboard',
            second : 'donation',
            third : 'event scheduling',
            fourth :  'hr mangmnt'
        },

        description : 'Our Hrms Management System is a comprehensive solution designed to streamline and enhance the administration and operations of temples. This system integrates various functions to facilitate efficient management of temple activities, donations, events, and communications. By leveraging advanced technology, we aim to support temples in maintaining their spiritual and administrative responsibilities with ease and effectiveness.',

        firstProjectTitle : 'The Protocal System',


        firstProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        firstProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        firstProjectBtnLink : '/',


        secondProjectTitle : 'The Protocal System',

        secondProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        secondProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        secondProjectBtnLink : '/',

        thirdProjectTitle : 'The Protocal System',

        
        thirdProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        thirdProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        thirdProjectBtnLink : '/',


    },
    {
        id : 'e_pass_portal',

        hero : 'https://images.pexels.com/photos/6113138/pexels-photo-6113138.jpeg?auto=compress&cs=tinysrgb&w=600',

        h1 : 'E-pass Portal',

        bannerImg : 'https://images.pexels.com/photos/4533747/pexels-photo-4533747.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        features : {
            first : 'admin Dashboard',
            second : 'donation',
            third : 'event scheduling',
            fourth :  'hr mangmnt'
        },

        description : 'Our Hrms Management System is a comprehensive solution designed to streamline and enhance the administration and operations of temples. This system integrates various functions to facilitate efficient management of temple activities, donations, events, and communications. By leveraging advanced technology, we aim to support temples in maintaining their spiritual and administrative responsibilities with ease and effectiveness.',

        firstProjectTitle : 'The Protocal System',


        firstProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        firstProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        firstProjectBtnLink : '/',


        secondProjectTitle : 'The Protocal System',

        secondProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        secondProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        secondProjectBtnLink : '/',

        thirdProjectTitle : 'The Protocal System',

        
        thirdProjectImg: 'https://images.pexels.com/photos/924633/pexels-photo-924633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        thirdProjectDesc: 'The Protocol System within our Temple Management System is designed to ensure the smooth and respectful handling of various ceremonial, administrative, and operational processes within the temple. This system enforces standard procedures and guidelines, maintaining the sanctity and tradition of temple activities while enhancing efficiency and organization. By automating and standardizing protocols, temples can ensure consistency, compliance, and the highest levels of service for all devotees and staff.',

        thirdProjectBtnLink : '/',


    },
    
]