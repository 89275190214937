import React, { useEffect, useState } from 'react';
import { ref as dbRef, get, set } from 'firebase/database';
import { database } from '../../firebaseConfig';
import { useAuth } from '../../Context/AuthContext';
import styles from './RecentRequests.module.css';
import bannerImg from "../../Assets/3iBannerDashBoard.svg";
import Sidebar from '../DashBoardSideBar/Sidebar';

// Example initial requests
const initialRequests = [
  // Your example initial requests go here
];

const RecentRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [entriesPerPage] = useState(5); // Number of entries per page
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      const requestRef = dbRef(database, `3I_Consulting/recent_requests`);
      get(requestRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            const allRequests = Object.values(data).flat();
            setRequests(allRequests);
          } else {
            set(requestRef, initialRequests)
              .then(() => {
                setRequests(initialRequests);
                console.log('Requests added successfully');
              })
              .catch((error) => console.error('Error adding requests:', error));
          }
        })
        .catch((error) => console.error('Error fetching data:', error))
        .finally(() => setLoading(false));
    }
  }, [user]);

  if (loading) {
    return <p>Loading...</p>;
  }

  // Pagination logic
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = requests.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(requests.length / entriesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className={styles.container}>
      <Sidebar className={styles.sidebar} />
      <div className={styles.content}>
        <div className={styles.bannerContainer}>
          <img src={bannerImg} alt="Banner" className={styles.bannerImage} />
        </div>
        <div className={styles.contentContainer}>
          <h2 className={styles.header}>Recent Requests</h2>
          <div className={styles.scrollableContent}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone No.</th>
                  <th>Date Added</th>
                  <th>Message</th>
                  <th>Detailed</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries.map((request, index) => (
                  <tr key={index}>
                    <td>{request.name}</td>
                    <td>{request.phoneNo}</td>
                    <td>{request.dateAdded}</td>
                    <td>{request.message}</td>
                    <td><button className={styles.button}>See more</button></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={styles.pagination}>
              <button 
                className={`${styles.pagination_button} ${currentPage === 1 ? styles.disabled : ''}`} 
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                &larr; Previous
              </button>
              <span className={styles.page_info}>
                Page {currentPage} of {totalPages}
              </span>
              <button 
                className={`${styles.pagination_button} ${currentPage === totalPages ? styles.disabled : ''}`} 
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next &rarr;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentRequests;
