import React from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { AllProduct } from "../../Context/AllProduct";
import { Link } from "react-router-dom";
import "./DetailedProduct.css"; // Import your CSS file

function DetailedProduct(props) {
  const params = useParams();
  const p_id = params.pId;

  // Assuming AllProduct is an array of product objects and each product has an 'id' field
  const product = AllProduct.find((product) => product.id === p_id);

  const location = useLocation();
  const productState = location.state;

  return (
    <>
      <img src={productState.bannerImage} className="bannerImage" />

      <div className="detailedProductContainer container-fluid">
        <h1 className="text-center">{productState.name}</h1>

        <div className="container my-5">
          <p className="proDescription ">{productState.description}</p>
        </div>

        <div className="container-fluid imgDivContainer">
          <div className="imgDiv">
            <img className="squareBoxes" src={productState.squareBoxes} />
          </div>
        </div>

        <div className="container-fluid liveProjectContainer">
          <div className="ProjectText text-center">
            <h6>
            <img className="OurProject mb-1" src={productState.squareBlueBox}/> Our Project
            </h6>
            <h5 className="mb-3">Live Projects</h5>
          </div>

          <div>
            {productState.projects.map((data, index) => (
              <div className="container my-5 liveProjectSection" key={index}>
                <div className="liveProjectSectionImg">
                  <img src={data.image} />
                </div>
                <div className="liveProjectSectionContent">
                  <div>
                    <p className="Heading">{data.name}</p>
                    <p className="projectDescription">{data.description}</p>
                    <button className="btn">
                      <Link to={data.website} className="btn-link">Visit Website</Link>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(DetailedProduct);
