import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Sidebar.module.css';
import DashBoardIcon from "../../Assets/Images/Dashboardicon.svg";
import GetHelpIcon from "../../Assets/Images/GetHelpIcon.svg";
import LogOutIcon from "../../Assets/Images/LogoutIcon.svg";
import RequestIcon from "../../Assets/Images/RequestIcon.svg";
import SettingsIcon from "../../Assets/Images/SettingsIcon.svg";
import SliderIcon from "../../Assets/Images/SliderIcon.svg";
import MenuIcon from "../../Assets/Images/MenuIcon.svg"; // Add a menu icon

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    setTimeout(() => {
      setIsCollapsed(false); // Close the sidebar after navigation on mobile
    }, 100);
  };

  const toggleSidebar = () => {
    setIsCollapsed(prevState => !prevState);
    // console.log(isCollapsed);
  };

  return (
    <div>
      <div className={styles.menuButton} onClick={toggleSidebar}>
        <img src={MenuIcon} alt="Menu Icon" />
      </div>
      <div className={`${styles.sidebar} ${isCollapsed ? styles.collapsed : ''}`}>
        <div className={styles.user}>
          <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="22" cy="22" r="22" fill="#D9D9D9"/>
            <text x="22" y="27" textAnchor="middle" fill="black" fontSize="20" fontFamily="Arial" dy=".3em">A</text>
          </svg>
          <div className={styles.userInfo}>
            <p className={styles.username}>Username</p>
            <p className={styles.userTitle}>CEO</p>
          </div>
          <svg className={styles.arrow} width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 15L8 11H16L12 15Z" fill="#6c757d"/>
          </svg>
        </div>
        <nav className={styles.nav}>
          <ul>
            <li className={styles.navItem} onClick={() => handleNavigation('/dashboard')}>
              <img src={DashBoardIcon} alt="Dashboard Icon" className={styles.icon} />
              Dashboard
            </li>
            <li className={styles.navItem} onClick={() => handleNavigation('/slider')}>
              <img src={SliderIcon} alt="Slider Icon" className={styles.icon} />
              Slider
            </li>
            <li className={styles.navItem} onClick={() => handleNavigation('/recentrequests')}>
              <img src={RequestIcon} alt="Request Icon" className={styles.icon} />
              Request
            </li>
            <li className={styles.navItem} onClick={() => handleNavigation('/dashboardLogin')}>
              <img src={LogOutIcon} alt="Logout Icon" className={styles.icon} />
              Logout
            </li>
          </ul>
        </nav>
        <div className={styles.settings}>
          <p>
            <img src={SettingsIcon} alt="Settings Icon" className={styles.icon} />
            Settings
          </p>
          <p>
            <img src={GetHelpIcon} alt="Get Help Icon" className={styles.icon} />
            Get Help?
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
