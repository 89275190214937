import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import HeroSection from "../../Components/HeroSection/HeroSection";
import productBG from "../../Assets/Images/Group 11673.png";
import "./Products.css";
import { Link } from "react-router-dom";
import { ProductContext } from "../../Context/Context";
import { getDatabase, ref, onValue } from "firebase/database";
import { database } from "../../firebaseConfig";

export default function Products() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  useEffect(() => {
    console.log("this is data");
    const carouselRef = ref(database, "3I_Consulting/products");
    onValue(carouselRef, (snapshot) => {
      const data = snapshot.val();
      setData(data);
      console.log(data);
    });
  }, []);

  return (
    <>
      <img src={productBG} className="productBG" />
      <div className="productSection">
        <div className="OuterContainer">
          {data.map((item, index) => {
            if (index % 2 !== 0) {
              return (
                <div className="productSectionA">
                  <div className="productSectionAImg" style={{ marginRight: '3%' }}>
                    <img src={item.mainImage} />
                  </div>
                  <div className="productSection2Content productSectionAContent">
                    {/* <div> */}
                      <h2>
                        {index + 1}. {item.name}
                      </h2>
                      <p className="itemPara">{item.description}</p>
                      <p className="itemPara"> What, Why and How?</p>
                      <div>
                        <p
                          className="ReadMore"
                          onClick={() => {
                            navigate("/detailed", { state: item });
                          }}
                        >
                          Read More
                        </p>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="productSectionA">
                  <div className="productSection2Content productSectionAContent">
                    {/* <div> */}
                      <h2>
                        {index + 1}. {item.name}
                      </h2>
                      <p className="itemPara">{item.description}</p>
                      <p className="itemPara"> What, Why and How?</p>
                      <div>
                        <p
                          className="ReadMore"
                          onClick={() => {
                            navigate("/detailed", { state: item });
                          }}
                        >
                          Read More
                        </p>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="productSectionAImg" style={{ marginLeft: '3%' }}>
                    <img src={item.mainImage} />
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
}
