import React, { useState } from "react";
import blueSquareImg from "../../Assets/Images/Rectangle 8.png";
import phoneIcon from "../../Assets/Images/Group 11648.svg";
import mapIcon from "../../Assets/Images/Group 11648 (1).svg";
import mailIcon from "../../Assets/Images/Group 11648 (2).svg";
import { ref as dbRef, push } from 'firebase/database';
import { database } from '../../firebaseConfig';
import ladyImg from "../../Assets/Images/image 135.png";

function HomeContactUs() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a reference to the `3I_Consulting/recent_requests/` path in the database
    const requestsRef = dbRef(database, '3I_Consulting/recent_requests');

    // Format the current date as DD/MM/YYYY
    const currentDate = new Date();
    const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')}/${String(currentDate.getMonth() + 1).padStart(2, '0')}/${currentDate.getFullYear()}`;

    // Create a new request object
    const newRequest = {
      name,
      phoneNo: phone,
      message,
      dateAdded: formattedDate,
    };

    try {
      // Push the new request to the database
      await push(requestsRef, newRequest);
      console.log('Data submitted successfully:', newRequest);

      // Clear the form fields
      setName('');
      setPhone('');
      setMessage('');
      setSuccessMessage('Submitted successfully!');

      // Clear any existing timeout
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout to clear the success message after 3 seconds
      const id = setTimeout(() => {
        setSuccessMessage('');
      }, 3000);

      // Store the timeout ID to clear if needed
      setTimeoutId(id);
    } catch (error) {
      console.error('Error submitting data:', error.message);
      console.error('Stack trace:', error.stack);
      setSuccessMessage('Error submitting data. Please try again.');
    }
  };

  return (
    <>
      <div className="container-fluid d-flex justify-content-center mt-5 homeContactSection">
        <div className="hContactContent mt-2">
          <h5 className="mb-3">
            {" "}
            <img className="mb-1 mr-2" src={blueSquareImg} alt="Blue Square" />
            Contact Us
          </h5>
          <h2 className="font-weight-bold">
            Contact Us for <br />
            <span style={{ color: "#0D98D9" }}>more Information</span>
          </h2>
          <div className="icons">
            <div className="d-flex align-items-center">
              <img
                style={{ width: "70px", height: "70px", objectFit: "cover" }}
                src={phoneIcon}
                alt="Phone Icon"
              />
              <p className="mb-0">
                Phone Number: <br />
                <span style={{ color: "#007bff" }}> +91 914 086 0949 </span>
              </p>
            </div>
            <div
              style={{ marginTop: 20, marginBottom: 20 }}
              className="d-flex"
            >
              <img
                style={{ width: "70px", height: "70px", objectFit: "cover" }}
                src={mapIcon}
                alt="Map Icon"
              />
              <p className="mb-0">
                Office Address: <br />
                <span style={{ color: "#007bff" }}>
                  N. 12/304, Triplex-3, <br />
                  J-56 Shivraj Nagar Colony,
                  <br />
                  Mahmoorganj, Varanasi.
                </span>
              </p>
            </div>
            <div className="d-flex align-items-center">
              <img
                style={{ width: "70px", height: "70px", objectFit: "cover" }}
                src={mailIcon}
                alt="Mail Icon"
              />
              <p className="mb-0">
                Mail Address: <br />
                <span style={{ color: "#007bff" }}> info@3iconsulting.in</span>
              </p>
            </div>
          </div>
        </div>
        <div
          style={{ width: "fit-content", height: "fit-content" }}
          className="hContactImg w-25"
        >
          <img
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            src={ladyImg}
            alt="Lady"
          />
        </div>
        <div className="hContactForm">
          <div className="container">
            <div>
              <h1>Get In Touch!</h1>
              <span
                style={{ backgroundColor: "#0D98D9", height: "10px" }}
                className="d-inline-block w-50"
              >
                &nbsp;
              </span>
            </div>
            <div className="row">
              <div className="col-md-12">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="exampleInputName">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName"
                      placeholder="Your Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputText">Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputText"
                      placeholder="Your Number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="formMsg">Your Message</label>
                    <textarea
                      id="formMsg"
                      className="form-control"
                      aria-label="With textarea"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <button
                    style={{ backgroundColor: '#0D98D9' }}
                    type="submit"
                    className="btn"
                  >
                    Submit
                  </button>
                </form>
                {successMessage && (
                  <div className="mt-3">
                    <p className="alert alert-success">{successMessage}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(HomeContactUs);
