import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import styles from './Analytics.module.css';
import SideIcon from "../../Assets/Images/AnalyticsNumberSideIcon.svg";
import PhoneIcon from "../../Assets/Images/PhoneRequestIcon.svg";
import RequestsIcon from "../../Assets/Images/RequestIconAnalytics.svg";
import WhatsAppIcon from "../../Assets/Images/WhatsAppIcon.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const data = {
  numbers: [
    { label: 'Number of Visits', value: '7,482,120' },
    { label: 'Monthly Traffic', value: '54,364' },
    { label: 'Phone Visits', value: '125,685' },
    { label: 'Requests', value: '125,685' },
  ],
  statistics: [
    { label: 'Weekly Target', percentage: 25 },
    { label: 'Monthly Target', percentage: 50 },
  ],
  graphData: {
    x: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    y: [100000, 200000, 150000, 300000, 250000, 400000, 350000, 450000, 300000, 200000, 300000, 400000],
  },
};

const AnalyticsComponent = () => {
  const chartData = {
    labels: data.graphData.x,
    datasets: [
      {
        label: 'Daily Views',
        data: data.graphData.y,
        borderColor: '#6693d0', // Line color
        backgroundColor: 'rgba(224, 244, 240, 0.5)', // Fill color with transparency
        fill: true,
        tension: 0.4,
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, 
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          borderDash: [8, 4],
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.performance}>
        <h2>30 Days Performance</h2>
        <div className={styles.numbers}>
          {data.numbers.map((item, index) => (
            <div key={index} className={styles.numberBox}>
              <div className={styles.numberText}>
                <p>{item.label}</p>
                <h3>{item.value}</h3>
              </div>
              <img src={SideIcon} className={styles.sideIcon} alt="Side Icon" />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.analytics}>
        <h3>Analytics</h3>
        
        <div className={styles.chartAndTargets}>
          <div className={styles.chartContainer}>
            <div className={styles.chartWrapper}> 
              <Line data={chartData} options={chartOptions} />
            </div>
          </div>
          <div className={styles.statistics}>
            {data.statistics.map((item, index) => (
              <div key={index} className={styles.statBox}>
                <p className={styles.statBoxHeading}>{item.label}</p>
                <div className={styles.progressBar}>
                  <div
                    className={styles.progress}
                    style={{ width: `${item.percentage}%` }}
                  ></div>
                </div>
                <p>{item.percentage}% achieved</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      <div className={styles.icons}>
        <div className={styles.iconBox}>
          <img src={PhoneIcon} alt="Phone Visits Icon" />
          <p>Phone Visits</p>
        </div>
        <div className={styles.iconBox}>
          <img src={RequestsIcon} alt="Requests Icon" />
          <p>Requests</p>
        </div>
        <div className={styles.iconBox}>
          <img src={WhatsAppIcon} alt="WhatsApp Requests Icon" />
          <p>WhatsApp Requests</p>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsComponent;
