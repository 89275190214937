import React from 'react';
import styles from './NandiTOS.module.css';
import nandiImage from '../../Assets/Images/NandiLogo.png'; 

const TermsOfService = () => {
  return (
    <div className={styles.fullScreenBackground}>
      <div className={styles.policyContainer}>
        <div className={styles.policyHeader}>
          <img src={nandiImage} alt="Nandi" className={styles.policyImage} />
          <div className={styles.policyTitle}>Terms of Service for Ask Nandi Chatbot</div>
        </div>
        <div className={styles.policyContent}>
          <div className={styles.policySection}>
            <strong>Introduction</strong>
            <p>Welcome to the Ask Nandi Chatbot. These Terms of Service (“Terms”) govern your use of the chatbot provided by 3i Consulting for Shri Kashi Vishwanath Temple (“we,” “us,” or “our”). By using the Ask Nandi Chatbot, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use the chatbot.</p>
          </div>
          <div className={styles.policySection}>
            <strong>Use of the Chatbot</strong>
            <ul>
              <li><strong>Eligibility:</strong> By using the chatbot, you represent that you are at least 18 years old or have obtained parental consent if you are under 18.</li>
              <li><strong>User Conduct:</strong> You agree to use the chatbot only for lawful purposes. You must not use the chatbot to:
                <ul>
                  <li>Post or transmit any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.</li>
                  <li>Impersonate any person or entity or misrepresent your affiliation with any person or entity.</li>
                  <li>Engage in any activity that would constitute a criminal offense or give rise to civil liability.</li>
                </ul>
              </li>
            </ul>
          </div>
          <div className={styles.policySection}>
            <strong>Content and Intellectual Property</strong>
            <ul>
              <li><strong>Ownership:</strong> All content provided through the chatbot, including text, graphics, logos, and software, is the property of 3i Consulting (Client - Shri Kashi Vishwanath Temple) and is protected by applicable intellectual property laws.</li>
              <li><strong>License:</strong> We grant you a limited, non-exclusive, non-transferable, and revocable license to use the chatbot for your personal, non-commercial use.</li>
              <li><strong>Restrictions:</strong> You must not copy, modify, distribute, sell, or lease any part of the chatbot or its content without our prior written consent.</li>
            </ul>
          </div>
          <div className={styles.policySection}>
            <strong>Privacy</strong>
            <p>Your use of the chatbot is also governed by our Privacy Policy, which explains how we collect, use, and protect your personal information. By using the chatbot, you consent to our collection and use of your information as described in the Privacy Policy.</p>
          </div>
          <div className={styles.policySection}>
            <strong>Disclaimers and Limitation of Liability</strong>
            <ul>
              <li><strong>No Warranty:</strong> The chatbot is provided “as is” and “as available” without any warranties of any kind, either express or implied. We do not warrant that the chatbot will be uninterrupted or error-free.</li>
              <li><strong>Limitation of Liability:</strong> To the maximum extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the chatbot, even if we have been advised of the possibility of such damages.</li>
            </ul>
          </div>
          <div className={styles.policySection}>
            <strong>Changes to These Terms</strong>
            <p>We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms. Your continued use of the chatbot after any changes indicates your acceptance of the new Terms.</p>
          </div>
          <div className={styles.policySection}>
            <strong>Termination</strong>
            <p>We may terminate or suspend your access to the chatbot at any time, without prior notice or liability, for any reason, including if you breach these Terms.</p>
          </div>
          <div className={styles.policySection}>
            <strong>Governing Law</strong>
            <p>These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law provisions. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in Varanasi, India.</p>
          </div>
          <div className={styles.policySection}>
            <strong>Contact Us</strong>
            <p>If you have any questions about these Terms, please contact us at:</p>
            <p>Email: info@3iconsulting.in<br />
            Address: Shri Kashi Vishwanath<br />
            CK 37/40,41,42 Bansphatak, Varanasi-221 001</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
