import React from 'react';
import styles from './NandiPrivacyPolicy.module.css';
import nandiImage from '../../Assets/Images/NandiLogo.png';

const PrivacyPolicy = () => {
  return (
    <div className={styles.fullScreenBackground}>
    <div className={styles.policyContainer}>
      <div className={styles.policyHeader}>
        <img src={nandiImage} alt="Nandi" className={styles.policyImage} />
        <div className={styles.policyTitle}>Privacy Policy for Ask Nandi Chatbot</div>
      </div>
      <div className={styles.policyContent}>
        <div className={styles.policySection}>
          <h2>Introduction</h2>
          <p><strong>Welcome to the Ask Nandi Chatbot.</strong> Your privacy is critically important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you interact with our chatbot.</p>
        </div>
        <div className={styles.policySection}>
          <h2>Information We Collect</h2>
          <ul>
            <li><strong>Personal Identification Information:</strong> Name, phone number, email address, and any other information you provide.</li>
            <li><strong>Usage Data:</strong> Interaction history, messages, and usage patterns.</li>
          </ul>
        </div>
        <div className={styles.policySection}>
          <h2>How We Use Your Information</h2>
          <ul>
            <li><strong>To Provide and Improve Our Services:</strong> Personalize user experience, respond to inquiries, and improve the functionality of the chatbot.</li>
            <li><strong>To Communicate With You:</strong> Send updates, notifications, and respond to your queries.</li>
            <li><strong>For Security and Compliance:</strong> Protect against fraud, unauthorized transactions, and comply with legal obligations.</li>
          </ul>
        </div>
        <div className={styles.policySection}>
          <h2>Sharing Your Information</h2>
          <p>We do not sell, trade, or otherwise transfer your personal information to outside parties except as outlined below:</p>
          <ul>
            <li><strong>Service Providers:</strong> We may share information with third-party service providers who assist us in operating the chatbot and providing services to you.</li>
            <li><strong>Legal Requirements:</strong> We may disclose your information if required by law or in response to valid requests by public authorities.</li>
          </ul>
        </div>
        <div className={styles.policySection}>
          <h2>Data Security</h2>
          <p>We implement a variety of security measures to maintain the safety of your personal information. However, please be aware that no method of transmission over the internet, or method of electronic storage, is 100% secure.</p>
        </div>
        <div className={styles.policySection}>
          <h2>Your Privacy Rights</h2>
          <p>Depending on your jurisdiction, you may have the following rights regarding your personal data:</p>
          <ul>
            <li><strong>Access and Update:</strong> You can access and update your personal information.</li>
          </ul>
        </div>
        <div className={styles.policySection}>
          <h2>Changes to This Privacy Policy</h2>
          <p>We may update our Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date.</p>
        </div>
        <div className={styles.policySection}>
          <h2>Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at:</p>
          <p><strong>Email:</strong> info@3iconsulting.in<br />
          <strong>Address:</strong> Shri Kashi Vishwanath<br />
          CK 37/40,41,42 Bansphatak, Varanasi-221 001</p>
        </div>
        <div className={styles.policySection}>
          <h2>Consent</h2>
          <p><strong>By using our chatbot, you consent to our Privacy Policy.</strong></p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
