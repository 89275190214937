import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext'; // Updated import
import styles from './DashboardLogin.module.css';
import backgroundImage from '../../Assets/Images/DashBoardLoginBg.svg';

const Login = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth(); // Use login from AuthContext

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { identifier, password } = event.target.elements;

    try {
      console.log('Submitting login form');
      console.log('Identifier:', identifier.value);
      console.log('Password:', password.value);

      // Call the login function from the AuthContext
      const user = await login(identifier.value, password.value);

      if (user) {
        // Successful login
        console.log('User found:', user);
        navigate('/dashboard');
      } else {
        // Handle case where login fails
        setErrorMessage('Wrong username/email or password');
        console.log('Wrong username/email or password');
      }
    } catch (error) {
      setErrorMessage('Error connecting to database');
      console.error('Database error:', error);
    }
  };

  return (
    <div className={styles.background} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className={styles.loginContainer}>
        <h2>Dashboard Login</h2>
        <form onSubmit={handleSubmit}>
          <input type="text" name="identifier" placeholder="Username or Email Address" required />
          <input type="password" name="password" placeholder="Password" required />
          <button type="submit">Log In</button>
          <a href="#" className={styles.forgotPassword}>Lost your password?</a>
        </form>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
      </div>
    </div>
  );
};

export default Login;
