import React from 'react';
import Sidebar from '../DashBoardSideBar/Sidebar';
import AnalyticsComponent from './Analytics';
import styles from './RenderAnalyticsComponent.module.css';
import bannerImg from "../../Assets/3iBannerDashBoard.svg";
// import { useAuth } from '../../Context/AuthContext'; // Updated import
const Dashboard = () => {

  return (
    <div className={styles.dashboardContainer}>
      <Sidebar />
      <div className={styles.content}>
        <div className={styles.bannerContainer}>
          <img src={bannerImg} alt="Banner" className={styles.bannerImage} />
        </div>
        <div className={styles.mainContent}>
          <AnalyticsComponent />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
