import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./Components/Header/Header";
import Home from "./Pages/Home/Home";
import Footer from "./Components/Footer/Footer";
import AboutUs from "./Pages/About/AboutUs";
import Contact from "./Pages/Contact/Contact";
import Products from "./Pages/Products/Products";
import Service from "./Pages/Service/Service";
import DetailedProduct from "./Components/DetailedProduct/DetailedProduct";
import SliderList from './Pages/ListOfSlider/Sliderlist';
import AddSlider from './Pages/AddSlider/Dashboard';
import RecentRequests from "./Pages/RecentRequests/RecentRequests";
import AnalyticsComponent from "./Pages/Analytics/RenderAnalyticsComponent";
import DashboardLogin from "./Pages/DashboardLogin/DashboardLogin";
import EditSlider from "./Pages/EditSlider/Dashboard";
import NandiPrivacyPolicy from "./Pages/NandiPrivacyPolicy/NandiPrivacyPolicy";
import NandiTOS from "./Pages/NandiTOS/NandiTOS";

import "./App.css";

function App() {
  const location = useLocation();
  const hiddenPaths = new Set([
    "/dashboard",
    "/addSlider",
    "/recentrequests",
    "/slider",
    "/dashboardLogin",
    "/editSlider",
    "/ask-nandi/privacy-policy",
    "/ask-nandi/terms-of-service"
  ]);
  
  const hideHeaderAndFooter = hiddenPaths.has(location.pathname);
  
  return (
    <div className="App">
      {!hideHeaderAndFooter && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/products" element={<Products />} />
        <Route path="/services" element={<Service />} />
        <Route path="/detailed" element={<DetailedProduct />} />
        <Route path="/slider" element={<SliderList />} />
        <Route path="/addSlider" element={<AddSlider />} />
        <Route path="/recentrequests" element={<RecentRequests />}/>
        <Route path="/dashboard" element={<AnalyticsComponent />}/>
        <Route path="/dashboardLogin" element={<DashboardLogin />}/>
        <Route path="/editSlider" element={<EditSlider />}/>
        <Route path="/ask-nandi/privacy-policy" element={<NandiPrivacyPolicy />}/>
        <Route path="/ask-nandi/terms-of-service" element={<NandiTOS />}/>
      </Routes>
      {!hideHeaderAndFooter && <Footer />}
    </div>
  );
}

export default App;
