import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, get } from 'firebase/database';
import { database } from '../../firebaseConfig';
import styles from './SliderList.module.css'; // Import CSS module
import bannerImg from "../../Assets/3iBannerDashBoard.svg";
import SideBar from '../DashBoardSideBar/Sidebar';
import { useAuth } from '../../Context/AuthContext'; // Updated import

const SliderList = () => {
    const [sampleSliders, setSampleSliders] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [entriesPerPage] = useState(5); // Number of entries per page
    const navigate = useNavigate();

    // Get user from context
    const { user } = useAuth(); 
    const userId = user?.uid; // Use the user ID set in context
    console.log(userId);

    const fetchSlidersData = async () => {
        if (!userId) {
            setErrorMessage('User not authenticated');
            return;
        }

        try {
            const dbRef = ref(database, `3I_Consulting/sliders/`);
            const snapshot = await get(dbRef);

            if (!snapshot.exists()) {
                setErrorMessage('No sliders found for the current user or data is not in expected format');
                return;
            }

            const slidersData = snapshot.val();

            if (slidersData) {
                // Converting object to array if needed
                const slidersArray = Object.entries(slidersData).map(([key, value]) => ({ id: key, ...value }));
                setSampleSliders(slidersArray);
            } else {
                setErrorMessage('No sliders found for the current user or data is not in expected format');
            }
        } catch (error) {
            setErrorMessage('Error connecting to database');
            console.error('Database error:', error);
        }
    };

    useEffect(() => {
        fetchSlidersData();
    }, [userId]);

    // Pagination logic
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = sampleSliders.slice(indexOfFirstEntry, indexOfLastEntry);
    const totalPages = Math.ceil(sampleSliders.length / entriesPerPage);

    const handleClickAddSlider = () => {
        navigate('/addSlider');
    };

    const handleEditClick = (slider) => {
        navigate('/editSlider', { state: { slider } });
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className={styles.sliderlist_container}>
            <SideBar className={styles.sidebar} />
            <div className={styles.content}>
                <div className={styles.sliderlist_bannercontainer}>
                    <img src={bannerImg} alt="Banner" className={styles.sliderlist_bannerimage} />
                </div>
                <div className={styles.sliderlist_contentContainer}>
                    <div className={styles.sliderlist_add_slider_container}>
                        <button className={styles.sliderlist_add_slider} onClick={handleClickAddSlider}>
                            Add Slider
                        </button>
                    </div>
                    {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
                    <table>
                        <thead>
                            <tr>
                                <th>Sr No.</th>
                                <th>Slider Title</th>
                                <th>Slider Image</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentEntries.map((slider, index) => (
                                <tr key={slider.id}>
                                    <td>{index + 1 + (currentPage - 1) * entriesPerPage}</td>
                                    <td>{slider.slider_title}</td>
                                    <td>
                                        <img src={slider.slider_image} alt={slider.slider_title} className={styles.sliderlist_slider_image}/>
                                    </td>
                                    <td>
                                        <span className={`${styles.sliderlist_status} ${slider.slider_status ? styles.sliderlist_enable : styles.sliderlist_disable}`}>
                                            {slider.slider_status ? 'Enable' : 'Disable'}
                                        </span>
                                    </td>
                                    <td>
                                        <button className={styles.sliderlist_action_button} onClick={() => handleEditClick(slider)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className={styles.pagination}>
                        <button 
                            className={`${styles.pagination_button} ${currentPage === 1 ? styles.disabled : ''}`} 
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            &larr; Previous
                        </button>
                        <span className={styles.page_info}>
                            Page {currentPage} of {totalPages}
                        </span>
                        <button 
                            className={`${styles.pagination_button} ${currentPage === totalPages ? styles.disabled : ''}`} 
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            Next &rarr;
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SliderList;
